import React from 'react';
import SomethingWrong from '@/components/SomethingWrong';

/**
 *
 * @param {Object} props
 * @param {Object} props.classes
 * @param {String} props.classes.container
 * @param {String} props.classes.image
 * @param {String} props.classes.header
 * @param {String} props.classes.btn
 * @param {Function} props.onRefresh
 * @returns
 */

export default function ErrorBoundary({ onRefresh }) {
  return (
    <SomethingWrong
      onRefresh={onRefresh}
      classes={{ header: 'text-2xl', btn: 'px-10 py-6' }}
    />
  );
}
