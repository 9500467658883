'use client';

import ErrorBoundary from '@/components/ErrorBoundary';

export default function Error({ reset }) {
  return (
    <div className="flex items-center justify-center h-screen ">
      <ErrorBoundary onRefresh={() => reset()} />
    </div>
  );
}
